<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Evaluation/s {{ convo.title }}<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="downloadZip()"
            >
              <a
                class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading p-0"
                :class="{'d-none': !exportLoading}"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Loading export..."
              ><i
                data-feather="loader"
              /></a>
              <span
                class="loop-loading"
                :class="{'d-none': exportLoading}"
              >
                <i
                  data-feather="file"
                  class="me-25"
                />
              </span>

              {{ !exportLoading ? 'Download zip with all' : 'Exporting applicants...' }}
            </a>
            <a
              v-if="convo.allow_retrieves"
              class="btn btn-icon btn-primary ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Add research line"
              @click="openRetrieves"
            ><i
              data-feather="thumbs-up"
              class="me-25"
            />Retrieve</a>
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <CompleteTable
          :sorted-field="sortedField"
          :data="applicants"
          :sort-mapping="sortMapping"
          :title="'ICREAs'"
          :total="applicantsTotal"
          :fetch-path="'convo/promotionIcreas'"
          :filter-path="'convo/promotionIcreas'"
        >
          <template v-slot="{field}">
            <td class="text-end">
              <span
                class="dis-ib"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View ICREA's profile"
              >
                <a
                  class="btn btn-icon rounded-pill btn-flat-primary"
                  :href="$router.resolve({name: 'evaluator.calls.promotion.evaluations.form-report', query: { user: field.id, convo: convo.id, actAs: $route.query.actAs}}).href"
                  target="_blank"
                >
                  <i data-feather="eye" />
                </a>
              </span>
              <span
                class="dis-ib ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Go to evaluation"
              >
                <a
                  class="btn btn-icon rounded-pill btn-flat-success"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDeleteWithInput"
                  :href="$router.resolve({name: 'evaluator.calls.promotion.evaluations.evaluate', query: { user: field.id, convo: convo.id, actAs: $route.query.actAs}}).href"
                  target="_blank"
                >
                  <i data-feather="award" />
                </a>
              </span>
              <span
                :class="{'d-none': exportingId == field.id}"
                class="ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Download zip"
                @click="downloadZip(field.id)"
              >
                <a
                  href="#"
                  class="btn btn-icon rounded-pill btn-flat-secondary"
                >
                  <i data-feather="download" />
                </a>
              </span>
              <span
                :class="{'d-none': exportingId != field.id}"
                class="ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Download zip"
                @click="downloadZip(field.id)"
              >
                <a
                  class="btn btn-icon btn-icon rounded-circle btn-flat-secondary loop-loading"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Loading export..."
                ><i
                  data-feather="loader"
                /></a>
              </span>
            </td>
          </template>
          <template #customfilters>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Researcher</label>
              <v-select
                label="name"
                :options="users"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                @input="saveFilter($event, 'user')"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Area</label>
              <v-select
                label="name"
                :options="areas"
                :get-option-key="option => option.id"
                @input="saveFilter($event, 'area_name')"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >ERC</label>
              <v-select
                label="name"
                :options="ercs"
                :get-option-key="option => option.id"
                @input="saveFilter($event, 'erc')"
                @search="onSearch({ name: $event }, 'erc/filter')"
              />
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Institution</label><!-- Its a text field because some evaluators might not have parametrized the institution -->
              <input
                type="text"
                class="form-control"
                name="nameseniorcall"
                @change="saveFilter($event.target.value, 'institution')"
              >
            </div>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Leave</label>
              <v-select
                label="label"
                :options="[
                  {label: 'All', name: 'Leave - All', value: null},
                  {label: 'Yes', name: 'Leave - Yes', value: true},
                  {label: 'False', name: 'Leave - False', value: false},
                ]"
                :get-option-key="option => option.name"
                @input="saveFilter($event, 'leave')"
              />
            </div>
            <hr>
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Host name</label> <!-- Filter by role evaluator -->
              <v-select
                label="name"
                :options="evaluatorsTotal"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'users/fetchEvaluatorsNoConvo')"
                @input="saveFilter($event, 'host_name')"
              />
            </div>
          </template>
        </CompleteTable>
      </div>
    </div>
    <TableColumns />
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'
import CompleteTable from '../../partials/components/CompleteTable.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    TableColumns,
    CompleteTable,
  },
  data() {
    return {
      exportLoading: false,
      pendingApplicants: 0,
      activeTab: null,
      selectedItem: {},
      sortedField: 'Researcher',
      defaultFields: [
        {
          name: 'Name',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Erc',
          checked: true,
          order: 3,
        },
        {
          name: 'Institution',
          checked: true,
          order: 4,
        },
        {
          name: 'Evaluation',
          checked: true,
          order: 5,
        },
      ],
      sortMapping: {
        Name: 'name',
        Area: 'areas_table',
        Erc: 'erc.code',
        Institution: 'host_table',
        Evaluation: 'submit',
      },
      loading: true,
      totalApplicants: 0,
      exportingId: null,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      areas: 'areas/areas',
      filters: 'filters/filters',
      fields: 'modals/tableSettingsFields',
      applicants: 'convo/applicants',
      applicantsTotal: 'convo/applicantsTotal',
      users: 'users/fakeUsers',
      ercs: 'erc/items',
      evaluatorsTotal: 'users/evaluators',
      loggedUser: 'auth/admin',
    }),
    url() {
      return Vue.prototype.$groupUrl
    },
    current_access() {
      return this.convo.current_access ? this.convo.current_access.type : null
    },
  },
  watch: {
    page() {
      this.getApplicants()
    },
    perPage() {
      this.getApplicants()
    },
  },
  async mounted() {
    await this.$store.dispatch('convo/getActivePromotion')
    if (!this.convo || (this.convo.days === 0 && !this.loggedUser.roles.includes('evaluator'))) {
      this.$router.push({ name: 'evaluator.calls.evaluations.profile' })
    } else {
      await this.$store.dispatch('convo/promotionIcreas', this.filters)
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
      if (this.areas.length === 0) {
        this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
      }
      await this.$store.dispatch('modals/fetchUserFields', 'promotion_evaluation')
      if (!this.fields || this.fields.length === undefined) {
      // If user doesnt have default fields, load the true default
        await this.$store.dispatch('modals/fetchFields', {
          fields: this.defaultFields,
          table: 'promotion_evaluation',
        })
      }
    }
  },
  methods: {
    async downloadZip(userId = null) {
      this.exportLoading = true
      this.exportingId = userId

      await this.$store.dispatch('convo/downloadZipApplicants', {
        convo: this.convo.id,
        user_id: userId,
      })

      this.exportingId = null
      this.exportLoading = false
    },
    saveFilter(value, field) {
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name && search.name !== '' && search.name.length > 2) {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
